import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import DynamicComponent from '../components/DynamicComponent'

// GQL fragments
import '../fragments/HeroContentQuery'
import '../fragments/HeroBlogContentQuery'
import '../fragments/TextImageContentQuery'
import '../fragments/OneColContentQuery'
import '../fragments/TwoColsContentQuery'
import '../fragments/ThreeColsContentQuery'
import '../fragments/TextImageSquaresContentQuery'
import '../fragments/QuoteContentQuery'
import '../fragments/StaticImageVideoContentQuery'
import '../fragments/TemplateElementContentQuery'
import '../fragments/DivideContentQuery'
import '../fragments/ComparisonTableContentQuery'
import '../fragments/LeveringstiderContentQuery'
import '../fragments/FormsContentQuery'
import '../fragments/DocumentsContentQuery'
import '../fragments/ContactsContentQuery'
import '../fragments/FaqContentQuery'

export default function StandardPage({ data }) {
  const pageInfo =
    data.craftPagesStandardEntry ||
    data.craftVidenVidenEntry ||
    data.craftNyhederNyhederEntry ||
    data.craftJobsJobsEntry

  const ogImageHero = pageInfo.dynamicContent?.find(
    (b) => b.__typename === 'Craft_dynamicContent_hero_BlockType'
  )?.backgroundImage[0]?.ogimage
  const ogImageBlogHero = pageInfo.dynamicContent?.find(
    (b) => b.__typename === 'Craft_dynamicContent_heroBlog_BlockType'
  )?.image[0]?.ogimage
  const ogImageOverride = pageInfo.ogImage[0]?.ogimage
  const ogImageGlobal = data.craftHeaderGlobalSet.ogImage[0]?.ogimage

  const ogImage =
    ogImageOverride || ogImageHero || ogImageBlogHero || ogImageGlobal

  return (
    <Layout
      pageTitle={pageInfo.title}
      pageDesc={pageInfo.metaDescription}
      ogImage={ogImage}
      darkMenu={pageInfo.darkMenu}
    >
      <DynamicComponent
        contentTypes={pageInfo.dynamicContent}
        slug={pageInfo.slug}
        title={pageInfo.title}
      />
    </Layout>
  )
}

export const query = graphql`
  query standardPageQuery($slug: String!) {
    craftHeaderGlobalSet {
      ogImage {
        ogimage
      }
    }
    craftPagesStandardEntry(slug: { eq: $slug }) {
      title
      metaDescription
      ogImage {
        ogimage
      }
      slug
      darkMenu
      dynamicContent {
        __typename
        ...HeroContent
        ...HeroBlogContent
        ...TextImageContent
        ...TextImageSquaresContent
        ...OneColContent
        ...TwoColsContent
        ...ThreeColsContent
        ...QuoteContent
        ...StaticImageVideoContent
        ...TemplateElementContent
        ...DivideContent
        ...FormsContent
        ...ComparisonTableContent
        ...LeveringstiderContent
        ...DocumentsContent
        ...ContactsContent
        ...FaqContent
      }
    }
    craftVidenVidenEntry(slug: { eq: $slug }) {
      title
      metaDescription
      ogImage {
        ogimage
      }
      slug
      darkMenu
      dynamicContent {
        __typename
        ...HeroContent
        ...HeroBlogContent
        ...TextImageContent
        ...TextImageSquaresContent
        ...OneColContent
        ...TwoColsContent
        ...ThreeColsContent
        ...QuoteContent
        ...StaticImageVideoContent
        ...TemplateElementContent
        ...DivideContent
        ...FormsContent
        ...ComparisonTableContent
        ...FaqContent
      }
    }
    craftJobsJobsEntry(slug: { eq: $slug }) {
      title
      metaDescription
      ogImage {
        ogimage
      }
      slug
      darkMenu
      dynamicContent {
        __typename
        ...HeroContent
        ...HeroBlogContent
        ...TextImageContent
        ...TextImageSquaresContent
        ...OneColContent
        ...TwoColsContent
        ...ThreeColsContent
        ...QuoteContent
        ...StaticImageVideoContent
        ...TemplateElementContent
        ...DivideContent
        ...FormsContent
        ...ComparisonTableContent
        ...FaqContent
      }
    }
    craftNyhederNyhederEntry(slug: { eq: $slug }) {
      title
      metaDescription
      ogImage {
        ogimage
      }
      slug
      darkMenu
      dynamicContent {
        __typename
        ...HeroContent
        ...HeroBlogContent
        ...TextImageContent
        ...TextImageSquaresContent
        ...OneColContent
        ...TwoColsContent
        ...ThreeColsContent
        ...QuoteContent
        ...StaticImageVideoContent
        ...TemplateElementContent
        ...DivideContent
        ...FormsContent
        ...ComparisonTableContent
        ...LeveringstiderContent
        ...FaqContent
      }
    }
  }
`
